import React from 'react';

function Feature({logo}) {
  return (
    <div  >

  <div className="flex justify-between items-center mb-2">
        <img src={logo} alt="Company Logo" className="h-32 w-44 pl-6" />
        <div/>
        <div className="text-right pr-8">
            <img
              src="./images/img.png"
              alt="Powerol Logo"
              className="h-20 w-38 mb-2"
            />
          </div>
        
      </div>
    <div className="max-w-4xl mx-auto relative mt-2 rounded-lg overflow-hidden p-8 " style={{ fontFamily: 'Times New Roman, Times, serif', fontSize: '16px' }}>
    <div
        className="absolute inset-0 bg-no-repeat bg-center opacity-25 z-0 "
        style={{ backgroundImage: "url('./images/img1.webp')" }}
      ></div>
      {/* Title */}
      <p className="text-s underline flex justify-center font-semibold mb-4">Salient Features of MAHINDRA POWEROL Silent Genset</p>

      {/* Features List */}
      <ul className="list-disc list-inside text-xs space-y-1  ">
        <li>Compact size with Manual/Automatic starting system. Remote Monitoring System will be provided as standard feature.</li>
        <li>Sound Proof, Weather Proof enclosure.</li>
        <li>Low vibration, best in class fuel consumption with electronic fuel injection system.</li>
        <li>Class G3 Governing in its range, which gives better accuracy in decreasing the speed drop in transient and lowering the recovery time of the speed.</li>
        <li>Low cost of service & spares.</li>
        <li>Low operation cost.</li>
        <li>Confirms to statutory Govt. CPCBIV+ emission & noise level norms.</li>
        <li>The Enclosure is of modular construction with the provision to assemble and dismantle easily.</li>
        <li>The sheet metal components are 9 tanks pre-treated and are Polyester based powder coated (inside as well as outside) for long life.</li>
        <li>All Nuts-bolts, hardware are of Stainless Steel for longer life.</li>
        <li>Battery is provided in a tray inside the enclosure.</li>
        <li>Doors are gasketed with high quality EPDN gaskets to avoid leakage of sound.</li>
        <li>Optimized EATS (Exhaust After Treatment System) is provided to control emission & exhaust noise.</li>
        <li>Specially designed sound attenuators are provided to control sound at air entry & exit points inside the enclosure.</li>
        <li>To make the system vibration-free, engine and alternator are mounted on specially designed anti-vibration pads mounted on base frame.</li>
        <li>The enclosure is designed and layout of the equipment is such that there is easy access to all serviceable parts.</li>
        <li>Adequate ventilation is provided to meet air requirement for combustion & heat removal.</li>
        <li>The silent DG set comes with the following safety features:</li>
        <ul className="list-disc list-inside ml-8">
          <li>High water temperature</li>
          <li>Low lube oil pressure</li>
          <li>Emergency stop push button outside the enclosure.</li>
          <li>EGR</li>
          <li>Cold Start feature (Optional)</li>
        </ul>
        <li>Specially designed Standard Control Panel is mounted inside enclosure itself.</li>
        <li>In-built draw-in type fuel tank of suitable capacity.</li>
        <li>With UV resistant powder coating, can withstand extreme environments.</li>
        <li>The walls of the enclosure are insulated with fire-retardant & noise absorbent foam/rockwool to comply with the noise level of 75 dB(A) at distance of 1 meter in open free field environment as per ISO 8528 part 10 specified by Ministry of Environment & Forest.</li>
        <li>Fluid drains for lube oil and fuel.</li>
      </ul>
    </div>
    <footer className="mt-8 print:fixed print:bottom-0 print:left-0 print:right-0 print:bg-white">
  <div className="container mx-auto px-4 py-2 flex flex-wrap items-end justify-between">
    <div className="w-full md:w-2/3 print:w-2/3">
      {/* Your address and other footer content */}
      <h3 className="font-bold text-xs mb-1">SHOWROOM AND CORRESPONDENCE: AG1, PLS PLAZA, 58 GANDHI ROAD, DEHRADUN (UTTARAKHAND).</h3>
            <h3 className="font-bold text-xs mb-1">HEAD OFFICE: HOUSE NO. 76, GREEN AVENUE CHANDRABANI DEHRADUN</h3>
            <h3 className="font-bold text-xs mb-1">BRANCH OFFICE: 79 HARILOK WEST JAWALAPUR HARIDWAR.</h3>
            <p className="text-xs">Tel (D): 0135-2650167, Mob: 9897072024, Email: enqdests@gmail.com, rohit95dmet@gmail.com</p>
    </div>
    <div className="w-full md:w-1/3 print:w-1/3 flex justify-end items-end mt-2 md:mt-0 print:justify-end">
      <img
        src="images/img1.webp"
        alt="Company Logo"
        className="w-auto h-12 md:h-16 print:h-20 object-contain"
      />
    </div>
  </div>
</footer>
    </div>
  );
}

export default Feature;

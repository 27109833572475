import React from 'react';
import SalesFront from './SalesFront';
import Feature from './Feature';
import TermCondition from './TermCondition';

function SalesCard({
  logo,
  quoteNumber,
  date,
  customerName,
  customerCity,
  customerState,
  kva,
  modelNo,
  bhp,
  phase,
  cylinder,
  panel,
  items,
  Amount,
  total
}) {
  // Calculate GST (18% of the price)
  // const gstAmount = items[0]?.Amount ? parseInt(items[0].Amount) * 0.18 : 0;
   const gstAmount = Amount ? parseInt(Amount) * 0.18 : 0;

  // Calculate the total (price + GST)
  const calculatedTotal = Amount ? parseInt(Amount) + gstAmount : 0;
console.log(Amount,"Amount");
  return (
    <div>
    <div className="max-w-4xl mx-auto rounded-lg overflow-hidden p-8">
    
  <div className='mt-1' > 
  <SalesFront  quoteNumber={quoteNumber} date={date} 
    logo={logo}
    kvaRating={kva} 
    customerCity={customerCity}
    customerName={customerName} 
    customerState={customerState} />
   </div>
  <div className='mt-2' >
  <Feature logo={logo}/>
  </div>
      {/* Quotation Table */}
      <div className='mt-40' >
      <div className="flex justify-between items-center mb-2">
        <img src={logo} alt="Company Logo" className="h-32 w-44 pl-6" />
        <div/>
        <div className="text-right pr-8">
            <img
              src="./images/img.png"
              alt="Powerol Logo"
              className="h-20 w-38 mb-2"
            />
          </div>        
      </div>
      <div className='max-w-4xl relative overflow-hidden' >
      <div
        className="absolute inset-0 bg-no-repeat bg-center mt-14 opacity-25 z-0 "
        style={{ backgroundImage: "url('./images/img1.webp')" }}
      ></div>
      <table className="w-full mb-8 mt-32 border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-2 text-left w-16">Sr. No.</th>
            <th className="border border-gray-300 p-2 text-center">Description</th>
            <th className="border border-gray-300 p-2 text-right w-32">Unit Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 p-2 align-top">1.</td>
            <td className="border border-gray-300 p-2">
              {/* <p className="font-semibold">{kva} KVA CPCB-IV+ </p> */}
              <p><strong>{kva} KVA CPCB-IV+</strong> Mahindra Silent D/G Set Comprising of Mahindra Engine Model <span className="font-semibold">{modelNo}</span> / {bhp} BHP/Water Cooled, 1500RPM coupled with <span className="font-semibold">{kva} KVA {phase} Phase</span> CG/ LS/ Stamford/ Equivalent make alternator, Turbocharged & Intercooled, {cylinder} cylinders make complete with all accessories including Base Frame, Fuel Tank, battery with leads, Sound Proof Canopy & <span className="font-semibold">{panel}</span> Control Panel.</p>
            </td>
            <td className="border border-gray-300 p-2 text-center align-top">{Amount ? `${parseInt(Amount).toLocaleString('en-IN')}/-` : '-'}</td>
          </tr>
          <tr>
            <td className="border border-gray-300 p-2">2.</td>
            <td className="border border-gray-300 p-2">GST@18%</td>
            <td className="border border-gray-300 p-2 text-center">{gstAmount ? `${gstAmount.toLocaleString('en-IN')}/-` : '-'}</td>
          </tr>
          <tr>
            <td className="border border-gray-300 p-2">3.</td>
            <td className="border border-gray-300 p-2">Transportation Charges</td>
            <td className="border border-gray-300 p-2 text-center">Inclusive</td>
          </tr>
          <tr>
            <td className="border border-gray-300 p-2">4.</td>
            <td className="border border-gray-300 p-2">Unloading Charges</td>
            <td className="border border-gray-300 p-2 text-center">By Customer</td>
          </tr>
        </tbody>
      </table>
      {/* Total */}
      <div className="text-right font-bold mb-2 text-xl">
        Total Amount: {calculatedTotal ? `₹${calculatedTotal.toLocaleString('en-IN')}/-` : '-'}
      </div>
      </div>

      <footer className="mt-20 print:fixed print:bottom-0 print:left-0 print:right-0 print:bg-white">
  <div className="container mx-auto px-4 py-2 flex flex-wrap items-end justify-between">
    <div className="w-full md:w-2/3 print:w-2/3">
      {/* Your address and other footer content */}
      <h3 className="font-bold text-xs mb-1">SHOWROOM AND CORRESPONDENCE: AG1, PLS PLAZA, 58 GANDHI ROAD, DEHRADUN (UTTARAKHAND).</h3>
            <h3 className="font-bold text-xs mb-1">HEAD OFFICE: HOUSE NO. 76, GREEN AVENUE CHANDRABANI DEHRADUN</h3>
            <h3 className="font-bold text-xs mb-1">BRANCH OFFICE: 79 HARILOK WEST JAWALAPUR HARIDWAR.</h3>
            <p className="text-xs">Tel (D): 0135-2650167, Mob: 9897072024, Email: enqdests@gmail.com, rohit95dmet@gmail.com</p>
    </div>
    <div className="w-full md:w-1/3 print:w-1/3 flex justify-end items-end mt-2 md:mt-0 print:justify-end">
      <img
        src="images/img1.webp"
        alt="Company Logo"
        className="w-auto h-12 md:h-16 print:h-20 object-contain"
        />
    </div>
  </div>
</footer>
      </div>
      <TermCondition logo={logo}/>
    </div>
    {/* <div className="bg-green-200 bg-opacity-80 text-center">
          <p className="text-sm">
            Design & Developed By Reactiveapps M. 8126813045
          </p>
        </div> */}
       </div> 
  );
}

export default SalesCard;



import React from 'react';

function TermCondition({logo}) {
  return (
    <div className='mt-96'>

    <div className="flex justify-between items-center mb-2">
    <img src={logo} alt="Company Logo" className="h-32 w-44 pl-6" />
    <div/>
    <div className="text-right pr-8">
        <img
          src="./images/img.png"
          alt="Powerol Logo"
          className="h-20 w-38 mb-2"
        />
      </div>
    
  </div>
    <div className="p-1 relative  rounded-lg max-w-4xl mx-auto mt-2">
    <div
        className="absolute inset-0 bg-no-repeat bg-center opacity-25  z-0 "
        style={{ backgroundImage: "url('./images/img1.webp')" }}
      ></div>
      <h2 className="text-base font-bold mb-1 text-center">Commercial Terms & Conditions</h2>

      <table className="w-full border border-black text-xs">
        <tbody>
          <tr>
            <td className="border border-black p-1 font-semibold">GST Tax</td>
            <td className="border border-black p-1">Taxes are Inclusive in the above prices.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Entry Permits</td>
            <td className="border border-black p-1">Shall be provided by the purchaser if applicable.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Dispatch</td>
            <td className="border border-black p-1">
              Supply within 6 - 8 Weeks from the date of receipt of your order along with advance, subject to force majeure conditions and unforeseen delay, which is beyond our / our manufacturers’ control.
            </td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Payment Terms</td>
            <td className="border border-black p-1">
              <ul className="list-none ml-2">
                <li>20% advance with order.</li>
                <li>80% one day before delivery.</li>
              </ul>
              <p>(NB: No cash payment will be accepted & payment received in advance will not be returned).</p>
            </td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Warranty</td>
            <td className="border border-black p-1">
              Warranty for the Genset is for 30 months from Date of invoice or 24 months from Date of commissioning or 5000 Hours of Operation whichever is earlier. Warranty for electrical and other proprietary items would be as per the respective manufacturer’s standard warranty clause. The warranty will not cover the normal wear and tear or damages caused by accident, wrong handling, and improper maintenance. For further details, please contact our authorized representative.
            </td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Insurance</td>
            <td className="border border-black p-1">Extra at cost, if required.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Force Majeure</td>
            <td className="border border-black p-1">
              Indications of delivery are given in good faith and we will not be responsible for delay in delivery due to war, fire, strikes, lockouts, power cuts, Acts of God, acts of public enemy, acts of Government, blockage, revolution, insurrection, civil commotion, terrorist activities, riots, floods, epidemics, quarantine restrictions, freight embargoes, or other circumstances beyond our reasonable control. Modalities of the order for the job shall be discussed after receipt of formal LOI from your end.
            </td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Validity</td>
            <td className="border border-black p-1">30 days from the date of this offer.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">Installation costs</td>
            <td className="border border-black p-1">Installation cost like change-over switch, cable, earthing, etc. will be paid by the customer, and Diesel Oil has to be arranged by Customer’s.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">COMMISSIONING</td>
            <td className="border border-black p-1">COMMISSIONING OF DG SET SHALL BE DONE BY OUR ENGINEER. ONLY ONE VISIT WILL BE GIVEN FOR COMMISSIONING FOR EXTRA VISIT CUSTOMER WILL HAVE TO BE PAID EXTRA VISIT CHARGES.</td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">General</td>
            <td className="border border-black p-1">
              <ul className="list-none ml-2">
                <li>All orders are subject to our acceptance in writing.</li>
                <li>All disputes are subject to Dehradun jurisdiction only.</li>
                <li>It is subject to no claim for damages incidental and/or consequential.</li>
                <li>Offer is subject to general terms and conditions of sales.</li>
                <li>We have not considered any statutory approval in our scope and the same shall be arranged by the purchaser without any cost implication to us.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className="border border-black p-1 font-semibold">ARBITRATION</td>
            <td className="border border-black p-1">
              Any dispute arising out of an order/contract against this quotation, the same will be referred to the sole arbitrator named as per the arbitration and conciliation act, 1996.
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="text-sm font-semibold mt-4">Bank Details of Sainko Systems</h3>
      <p className="text-sm"><strong>Account Name:</strong> Sainko Systems</p>
      <p className="text-sm"><strong>Bank Name:</strong> HDFC BANK</p>
      <p className="text-sm"><strong>Address:</strong> 32, Arhat Bazar, Dehradun</p>
      <p className="text-sm"><strong>A/c. No.:</strong> 50200046934692</p>
      <p className="text-sm"><strong>IFSC Code:</strong> HDFC0000893</p>

      <div className="mt-3 text-sm">
        <p>For Sainko Systems</p>
        <p><strong>Rohit Srivastava</strong></p>
        <p>Authorized Signatory</p>
      </div>
    </div>
    <footer className="mt-6 print:fixed print:bottom-0 print:left-0 print:right-0 print:bg-white">
  <div className="container mx-auto px-4 py-2 flex flex-wrap items-end justify-between">
    <div className="w-full md:w-2/3 print:w-2/3">
      {/* Your address and other footer content */}
      <h3 className="font-bold text-xs mb-1">SHOWROOM AND CORRESPONDENCE: AG1, PLS PLAZA, 58 GANDHI ROAD, DEHRADUN (UTTARAKHAND).</h3>
            <h3 className="font-bold text-xs mb-1">HEAD OFFICE: HOUSE NO. 76, GREEN AVENUE CHANDRABANI DEHRADUN</h3>
            <h3 className="font-bold text-xs mb-1">BRANCH OFFICE: 79 HARILOK WEST JAWALAPUR HARIDWAR.</h3>
            <p className="text-xs">Tel (D): 0135-2650167, Mob: 9897072024, Email: enqdests@gmail.com, rohit95dmet@gmail.com</p>
    </div>
    <div className="w-full md:w-1/3 print:w-1/3 flex justify-end items-end mt-2 md:mt-0 print:justify-end">
      <img
        src="images/img1.webp"
        alt="Company Logo"
        className="w-auto h-12 md:h-16 print:h-20 object-contain"
      />
    </div>
  </div>
</footer>
    </div>
  );
}

export default TermCondition;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link,NavLink } from 'react-router-dom';
import QuotationManager from './components/QuotationManager';
import QuotationManager2 from './components/sales/QuotationManager2';

function Apps() {
  return (
    <Router>
      <div className="App">
        <nav className="bg-blue-500 p-4">
          <ul className="flex space-x-4">
          <li>
  <NavLink
    to="/sales"
    className={({ isActive }) =>
      isActive
        ? "text-white text-blue-800 hover:text-blue-200"
        : "text-white hover:text-blue-200"
    }
  >
    Sales
  </NavLink>
</li>
<li>
  <NavLink
    to="/service"
    className={({ isActive }) =>
      isActive
        ? "text-white text-blue-800 hover:text-blue-200"
        : "text-white hover:text-blue-200"
    }
  >
    Service
  </NavLink>
</li>
          </ul>
        </nav>

        <div className="p-6 max-w-4xl mx-auto">
          <Routes>
            <Route path="/sales" element={<QuotationManager2 />} />
            <Route path="/service" element={<QuotationManager />} />
            <Route path="/" element={<h1 className="text-2xl font-bold">Welcome to the Quotation App</h1>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default Apps;
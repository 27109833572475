import React from 'react';

function SalesFront({logo, kvaRating, customerCity, customerName, customerState, quoteNumber, date}) {
  return (
    <div className='flex flex-col min-h-screen mx-auto relative  overflow-hidden'>
      <div
        className="absolute inset-0 bg-no-repeat bg-center opacity-25 z-0"
        style={{ backgroundImage: "url('./images/img1.webp')" }}
      ></div>
      <div className='flex-grow'>
        <div className="flex justify-between items-center mt-0 mb-6">
          <img src={logo} alt="Company Logo" className="h-32 w-44 pl-6" />
          <div className="text-right pr-8">
            <img
              src="./images/img.png"
              alt="Powerol Logo"
              className="h-20 w-38 mb-2"
            />
          </div>
        </div>
        <div className="text-left ml-10 mt-0">
          <p className="text-sm">Ref: {quoteNumber}</p>
          <p className="text-sm">Date: {date}</p>
        </div>
        <div className="max-w-4xl mx-auto bg-white rounded-lg overflow-hidden p-8" style={{ fontFamily: 'Times New Roman, Times, serif', fontSize: '16px' }}>
          {/* Header */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-1">To,</h3>
            <p className="font-semibold ml-1">{customerName}</p>
            <p className='ml-1'>{customerCity}</p>
            <p className='ml-1'>{customerState}</p>
          </div>
          <div className="mb-1">
            <p className="mb-1">Sub: Quotation for {kvaRating} KVA CPCB-IV+ Mahindra Powerol Silent DG set.</p>
          </div>

          {/* Body */}
          <div className="mb-3">
            <p>Dear Sir,</p>
            <p className="mb-2">
              We thank you very much for the interest shown in Mahindra Powerol Genset.
            </p>
            <p className="mb-2">
              Mahindra Powerol offers you an end-to-end solution for your backup power requirements from 5 kVA to 625 kVA gensets in single configuration and up to 4000 kVA in multiple configurations.
            </p>
            <p className="mb-2">
              Mahindra Powerol has won the prestigious JQM & DEMING AWARD, which is the world-renowned Japanese award for Quality. We are the only Indian Industrial Engine manufacturer in the world to win this prize.
            </p>
            <p className="mb-2">
              All Mahindra Engines meet the stringent CPCB IV+ norms for Noise and Exhaust Emission.
            </p>
            <p className="mb-2">
              Mahindra engines are manufactured at state-of-the-art manufacturing facilities located in Chakan, Pune, and Nagpur, which are equipped with fully automated, controlled environment engine assembly and Quality control systems to maintain the highest level of engine quality standards.
            </p>
            <p className="mb-2">
              Presently, more than 4,00,000 Mahindra Powerol gensets are powering diversified segments like Engineering, Realty, Retail, IT, Telecom, BFSI, Manufacturing, Pharma, Textile, Oil & Gas, DGSND.
            </p>
            <p>
              It will be our pleasure to serve you. Thanking you and assuring you of our prompt attention at all times.
            </p>
          </div>

          {/* Signature */}
          <div className="mt-4">
            <p>For <strong>Sainko Systems</strong></p>
            <p className="mt-4"><strong>Rohit Srivastava</strong></p>
            <p>Authorized Signatory</p>
          </div>
        </div>
      </div>

      {/* Footer */}
<footer className="mt-8 print:fixed print:bottom-0 print:left-0 print:right-0 print:bg-white">
  <div className="container mx-auto px-4 py-2 flex flex-wrap items-end justify-between">
    <div className="w-full md:w-2/3 print:w-2/3">
      {/* Your address and other footer content */}
      <h3 className="font-bold text-xs mb-1">SHOWROOM AND CORRESPONDENCE: AG1, PLS PLAZA, 58 GANDHI ROAD, DEHRADUN (UTTARAKHAND).</h3>
            <h3 className="font-bold text-xs mb-1">HEAD OFFICE: HOUSE NO. 76, GREEN AVENUE CHANDRABANI DEHRADUN</h3>
            <h3 className="font-bold text-xs mb-1">BRANCH OFFICE: 79 HARILOK WEST JAWALAPUR HARIDWAR.</h3>
            <p className="text-xs">Tel (D): 0135-2650167, Mob: 9897072024, Email: enqdests@gmail.com, rohit95dmet@gmail.com</p>
    </div>
    <div className="w-full md:w-1/3 print:w-1/3 flex justify-end items-end mt-2 md:mt-0 print:justify-end">
      <img
        src="images/img1.webp"
        alt="Company Logo"
        className="w-auto h-12 md:h-16 print:h-20 object-contain"
      />
    </div>
  </div>
</footer>
              </div>

    // </div>
  );
}




export default SalesFront;
export const modelData2 =[

    { sr: "", Phase:'1', Panel:'Manual', qty: "1", Bhp: "16.3", Kva:"10", Aspiration:"Naturally Aspirated", Cylinder:"2" , modal: "M2155G1", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "18",   Kva:"15", Aspiration:"Naturally Aspirated", Cylinder:"2" , modal: "M2155G2", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "22.7", Kva:"15", Aspiration:"Naturally Aspirated", Cylinder:"3" , modal: "M3205G1", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "25.2", Kva:"20", Aspiration:"Naturally Aspirated", Cylinder:"3" , modal: "M3205G2", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "30.5", Kva:"22.5", Aspiration:"Turbocharged", Cylinder:"3" , modal: "M3205G3", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "35.1", Kva:"25", Aspiration:"Turbocharged", Cylinder:"3" , modal: "M3205G4", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "40", Kva:"30", Aspiration:"Turbocharged & Intercooled", Cylinder:"3" , modal: "M3205G5", Amount:"15879"},
    { sr: "", Phase:'1', Panel:"Manual", qty: "1", Bhp: "40", Kva:"35", Aspiration:"Turbocharged & Intercooled", Cylinder:"3" , modal: "M3205G5", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "51.8", Kva:"40", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "M4275G1", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "51.8", Kva:"45", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "M4275G1", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "65.4", Kva:"50", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "V4355G1", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "75.5", Kva:"58.5", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "V4355G2", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "101.3", Kva:"75", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "V4355G3", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "101.3", Kva:"82.5", Aspiration:"Turbocharged & Intercooled", Cylinder:"4" , modal: "V4355G4", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "126", Kva:"100-4Cyl", Aspiration:"TCIC", Cylinder:"4" , modal: "H4485G2", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "156", Kva:"125-4Cyl", Aspiration:"TCIC", Cylinder:"4" , modal: "H4485G1", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "199", Kva:"160", Aspiration:"TCIC", Cylinder:"6" , modal: "H6725G2", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "223", Kva:"180", Aspiration:"TCIC", Cylinder:"6" , modal: "H6725G3", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "247", Kva:"200", Aspiration:"TCIC", Cylinder:"6" , modal: "H6725G4", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "310", Kva:"250", Aspiration:"TCIC", Cylinder:"6" , modal: "H6935G1", Amount:"15879"},
    { sr: "", Phase:'3', Panel:"Manual", qty: "1", Bhp: "390", Kva:"320", Aspiration:"TCIC", Cylinder:"6" , modal: "H6935G2", Amount:"15879"},
    


    
]

import React, { useState, useEffect } from 'react';
// import { FaTrash, FaPlus } from 'react-icons/fa';
import { modelData2 } from '../Data2';
// import SalesFront from './SalesFront';

function SalesForm({ onSubmit, initialData, ...otherProps }) {
  const [formData, setFormData] = useState(initialData || {
    logo: './images/sainko.jpeg',
    dealerName: 'SAINKO SYSTEMS',
    date: new Date().toISOString().split('T')[0],
    quoteNumber: `QUO/GEN/MAH/CPCB-IV+/${new Date().getFullYear()}-${new Date().getFullYear() + 1}/`,
    customerName: '',
    customerCity: '',
    customerState: '',
    modelCategory: 'CPCB4',
    modelNo: '',
    kva: '',
    panel: '',
    phase: '',
    cylinder: '',
    bhp: '',
    Amount:'',
    items: []
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'modelNo') {
      const selectedModel = modelData2.find(model => model.modal === value);
      if (selectedModel) {
        setFormData(prevData => ({
          ...prevData,
          kva: selectedModel.Kva,
          panel: selectedModel.Panel,
          phase: selectedModel.Phase,
          cylinder: selectedModel.Cylinder,
          bhp: selectedModel.Bhp,
          Amount:selectedModel.Amount,
          items: [
            {
              partno: "1",
              name: `${selectedModel.Kva} KVA DG Set`,
              qty: "1",
              price: selectedModel.Amount,
              modal: value
            }
          ]
        }));
      }
    }
  };

  // const handleItemChange = (index, field, value) => {
  //   const newItems = [...formData.items];
  //   newItems[index] = { ...newItems[index], [field]: value };
  //   setFormData(prevData => ({
  //     ...prevData,
  //     items: newItems
  //   }));
  // };

  // const addItem = () => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     items: [
  //       ...prevData.items, 
  //       { 
  //         partno: "", 
  //         name: "", 
  //         qty: "1", 
  //         price: "0", 
  //         modal: formData.modelNo 
  //       }
  //     ]
  //   }));
  // };

  // const removeItem = (index) => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     items: prevData.items.filter((_, i) => i !== index)
  //   }));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div>

    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      {/* ... (keep the existing form fields) ... */}

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="modelNo">
          Model Number
        </label>
        <select
          id="modelNo"
          name="modelNo"
          value={formData.modelNo}
          onChange={handleChange}
          className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          // className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Select a model</option>
          {modelData2.map(model => (
            <option key={model.modal} value={model.modal}>{model.modal}</option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerName">
          Customer Name
        </label>
        <input
          type="text"
          id="customerName"
          name="customerName"
          value={formData.customerName}
          onChange={handleChange}
          // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          required
        />
      </div>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div >
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerCity">
            City
          </label>
          <input
            type="text"
            id="customerCity"
            name="customerCity"
            value={formData.customerCity}
            onChange={handleChange}
            // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
            required
          />
          </div>
          <div >
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerState">
            State
          </label>
          <input
            type="text"
            id="customerState"
            name="customerState"
            value={formData.customerState}
            onChange={handleChange}
            // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
            required
          />
        </div>
        
        </div>
        <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quoteNumber">
          Quote Number
        </label>
        <input
          type="text"
          id="quoteNumber"
          name="quoteNumber"
          value={formData.quoteNumber}
          onChange={handleChange}
          // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          required
        />
      </div>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="kva">KVA</label>
          <input
            type="text"
            id="kva"
            name="kva"
            value={formData.kva}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="panel">Panel</label>
          <input
            type="text"
            id="panel"
            name="panel"
            value={formData.panel}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phase">Phase</label>
          <input
            type="text"
            id="phase"
            name="phase"
            value={formData.phase}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cylinder">Cylinder</label>
          <input
            type="text"
            id="cylinder"
            name="cylinder"
            value={formData.cylinder}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bhp">BHP</label>
          <input
            type="text"
            id="bhp"
            name="bhp"
            value={formData.bhp}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Amount">Amount</label>
          <input
            type="text"
            id="Amount"
            name="Amount"
            value={formData.Amount}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
          />
        </div>
      </div>

      {/* ... (keep the existing items table and buttons) ... */}

      <button
        type="submit"
        className="mt-6 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
        Generate Quotation
      </button>
    </form>
    {/* <SalesFront kvaRating={formData.kva} /> */}
        </div>
  );
}

export default SalesForm;
